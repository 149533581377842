<template>
  <div id="items">
    <!-- Filters -->
    <b-card v-if="showFilters" no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('common.filters') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('common.start_at')" label-for="state-filter">
              <b-overlay :show="loadInvoices">
                <b-form-datepicker
                  id="start-at"
                  v-model="params.startAt"
                  :max="params.endAt"
                  :placeholder="$t('common.start_at')"
                  :locale="$i18n.locale"
                  clearable="true"
                  reset-button
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('common.end_at')" label-for="state-filter">
              <b-overlay :show="loadInvoices">
                <b-form-datepicker
                  id="end-at"
                  v-model="params.endAt"
                  :min="params.startAt"
                  :placeholder="$t('common.end_at')"
                  :locale="$i18n.locale"
                  clearable="true"
                  reset-button
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <enum-select
              id="state-filter"
              v-model="params.invoiceState"
              enum-class="InvoiceState"
              :label="$t('marketplace_accounting.filter.status')"
              :overlay="loadInvoices"
            />
          </b-col>
          <b-col cols="12" md="4">
            <enum-select
              id="state-filter"
              v-model="params.invoiceType"
              enum-class="InvoiceType"
              :label="$t('marketplace_accounting.filter.invoice_type')"
              :overlay="loadInvoices"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card class="mb-0" no-body>
      <app-data-table-top
        :disabled="loadInvoices"
        :per-page="params.numberOfItemsPerPage"
        show-filter
        show-per-page
        show-search
        condensed="lg"
        size="md"
        table-name="accountingTable"
        @table-select-per-page="params.numberOfItemsPerPage = $event"
        @table-search-text="params.searchQuery = $event"
        @table-filter="showFilters = $event"
      >
        <template #actions>
          <b-button class="d-flex align-items-center mr-2" @click="exportInvoices">
            <font-awesome-icon class="mr-50" icon="upload" />
            {{ $t('action.export') }}
          </b-button>
        </template>
      </app-data-table-top>

      <!-- TABLE -->
      <app-data-table
        ref="accountingTable"
        table-name="accountingTable"
        :items="invoices"
        :fields="itemsFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="params.numberOfItemsPerPage"
        :overlay="loadInvoices"
        :bottom-table="{ totalCount: totalInvoices }"
        manual-pagination
        @table-select-per-page="params.numberOfItemsPerPage = $event"
        @pagination="params.page = $event"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- HEAD -->
        <template #head(invoiceReference)>
          {{ $t('accounting.number') }}
        </template>
        <template #head(dueDate)>
          {{ $t('common.date') }}
        </template>
        <template #head(type)>
          {{ $t('accounting.type') }}
        </template>
        <template #head(clientName)>
          {{ $t('accounting.client') }}
        </template>
        <template #head(clientCategory)>
          {{ $t('accounting.category') }}
        </template>
        <template #head(clientCountry)>
          {{ $t('accounting.country') }}
        </template>
        <template #head(beforeTax)>
          {{ $t('accounting.before_tax') }}
        </template>
        <template #head(totalVat)>
          {{ $t('accounting.total_vat') }}
        </template>
        <template #head(invoiceState)>
          {{ $t('accounting.status') }}
        </template>
        <!-- CELL -->
        <!-- number -->
        <template #cell(invoiceReference)="{ item }">
          <span class="text-nowrap"> {{ item.reference }} </span>
        </template>
        <!-- dueDate -->
        <template #cell(dueDate)="{ item }">
          <span class="text-nowrap">{{ item.date | dateFormatUtc }}</span>
        </template>
        <!-- type -->
        <template #cell(type)="{ item }">
          <span class="text-nowrap"> {{ item.invoiceType | enumTranslate('invoice_type') }} </span>
        </template>
        <!-- client -->
        <template #cell(clientName)="{ item }">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar :src="item.client.logoFileUrl" />
            </template>
            <span class="font-weight-bold d-block text-nowrap client">
              {{ item.client.legalName }}
            </span>
          </b-media>
        </template>
        <!-- category -->
        <template #cell(clientCategory)="{ item }">
          <span class="text-nowrap"> {{ item.client.mainTypology | enumTranslate('organization_typology') }} </span>
        </template>
        <!-- country -->
        <template #cell(clientCountry)="{ item }">
          <span class="text-nowrap"> {{ item.client.country }} </span>
        </template>
        <!-- beforeTax -->
        <template #cell(beforeTax)="{ item }">
          <span class="text-nowrap"> {{ item.discountedPrice | priceFormat }} </span>
        </template>
        <!-- totalVat -->
        <template #cell(totalVat)="{ item }">
          <span class="text-nowrap">{{ item.priceIncludingTaxes | priceFormat }} </span>
        </template>
        <!-- status -->
        <template #cell(invoiceState)="{ item }">
          <b-badge v-if="item.state" :variant="resolveInvoiceStateVariant(item.state)" pill>
            {{ item.state | enumTranslate('invoice_state') }}
          </b-badge>
        </template>
        <!-- action -->
        <template #cell(actions)="{ item }">
          <div id="accounting-table-data-table-actions">
            <div class="text-nowrap">
              <b-dropdown v-if="item.quotes.length > 0 || item.paymentUrl" id="accounting-table-data-table-actions-dropdown" no-caret variant="link">
                <template #button-content>
                  <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
                </template>
                <!--show-->
                <b-dropdown-item v-if="item.quotes.length > 0" :disabled="!$can('OPERATOR_ACCOUNTING_VIEW')" :to="{ name: 'orderAccountingShow', params: { id: item.quotes[0].id } }">
                  <feather-icon id="accounting-table-data-table-dropdown-action-show-invoice" icon="EyeIcon" size="16" />
                  <span class="align-middle ml-50">{{ $t('action.show') }}</span>
                </b-dropdown-item>
                <!--show stripe-->
                <b-dropdown-item v-if="item.paymentUrl" :disabled="!$can('OPERATOR_ACCOUNTING_VIEW')" :href="item.paymentUrl" target="_blank">
                  <feather-icon id="accounting-table-data-table-dropdown-action-show-stripe" icon="AlignJustifyIcon" size="16" />
                  <span class="align-middle ml-50">{{ $t('action.show_in_stripe') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </template>
      </app-data-table>
    </b-card>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { downloadMarketplaceInvoiceCSV, fetchItemsRequest } from '@/request/globalApi/requests/accountingRequests'

import AppDataTable from '@/components/AppDataTable.vue'
import AppDataTableTop from '@/components/AppDataTableTop.vue'

export default {
  name: 'AccountingList',

  components: {
    AppDataTable,
    AppDataTableTop,
  },
  mixins: [UIHelper],

  data() {
    return {
      showFilters: false,
      itemsFields: [
        { key: 'invoiceReference', sortable: true },
        { key: 'dueDate', sortable: true },
        { key: 'type' },
        { key: 'clientName', sortable: true },
        { key: 'clientCategory', sortable: true },
        { key: 'clientCountry', sortable: true },
        { key: 'beforeTax' },
        { key: 'totalVat' },
        { key: 'invoiceState', sortable: true },
        { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
      ],
      loadInvoices: false,
      invoices: null,
      totalInvoices: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        /* invoiceReferenceOrder: null,
        dueDateOrder: null,
        invoiceStateOrder: null,
        clientNameOrder: null,
        clientCategoryOrder: null,
        clientCountryOrder: null, */
        // FILTERS
        startAt: '',
        endAt: '',
        statesCodes: [],
        invoiceType: [],
        invoiceState: [],
        paymentMethod: [],
        searchQuery: null,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: 'invoiceReference',
      sortDesc: false,
    }
  },

  computed: {
    allFilters() {
      return {
        // PAGE
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        // SORTING
        /* invoiceReferenceOrder: this.params.invoiceReferenceOrder,
        dueDateOrder: this.params.dueDateOrder,
        invoiceStateOrder: this.params.invoiceStateOrder,
        clientNameOrder: this.params.clientNameOrder,
        clientCategoryOrder: this.params.clientCategoryOrder,
        clientCountryOrder: this.params.clientCountryOrder, */
        // FILTERS
        startAt: this.params.startAt,
        endAt: this.params.endAt,
        statesCodes: this.params.statesCodes,
        invoiceType: this.params.invoiceType,
        invoiceState: this.params.invoiceState,
        paymentMethod: this.params.paymentMethod,
        searchQuery: this.params.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      }
    },
  },

  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchInvoices()
  },

  methods: {
    APIFetchInvoices() {
      const payload = {}
      if (this.sortBy) {
        switch (this.sortBy) {
          case 'invoiceReference':
            payload.invoiceReferenceOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'dueDate':
            payload.dueDateOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'invoiceState':
            payload.invoiceStateOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'clientName':
            payload.clientNameOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'clientCategory':
            payload.clientCategoryOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'clientCountry':
            payload.clientCountryOrder = this.sortDesc ? 'asc' : 'desc'
            break
          default:
            payload.invoiceReferenceOrder = 'asc'
        }
      }

      this.loadInvoices = true
      fetchItemsRequest({ ...this.params, ...payload }).then(response => {
        const { invoices, totalItems } = response.data
        this.invoices = invoices
        this.totalInvoices = totalItems
      }).finally(() => {
        this.loadInvoices = false
      })
    },
    refetchData() {
      this.APIFetchInvoices()
      this.$refs.accountingTable.refresh()
    },
    exportInvoices() {
      downloadMarketplaceInvoiceCSV()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.select-search {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.client {
  margin-top: 0.5rem;
}
</style>
